<template>
	<div class="app-container">
		<dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed" @toggledCard="isCollapsed = !isCollapsed">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">De la </div>
					<el-date-picker class="fullWidth" v-model="filters.from.value" value-format="yyyy-MM-dd" type="date" @change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Pana la </div>
					<el-date-picker class="fullWidth" v-model="filters.to.value" value-format="yyyy-MM-dd" type="date" @change="applyFilters">
					</el-date-picker>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Comentarii</div>
					<el-select class="fullWidth" v-model="filters.comments.value" placeholder="Toate" @change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option label="Cu comentarii" value="1"></el-option>
						<el-option label="Fara comentarii" value="2"></el-option>
					</el-select>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Tip evaluari</div>
					<el-select class="fullWidth" v-model="filters.evaltype.value" placeholder="Toate" @change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option label="Pozitive" value="3"></el-option>
						<el-option label="Neutre" value="2"></el-option>
						<el-option label="Negative" value="1"></el-option>
					</el-select>
				</el-col>
			</el-row>
		</dd-card>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>Informatii</span>
			</div>
			<div class="body">
				<div class="title">Intrebari chestionar de evaluare</div>
				<ol class="questions">
					<li>Pe baza experientei tale cu CEL.ro, care este probabilitatea de a ne recomanda celor dragi?</li>
					<li>Cum apreciati durata de timp necesară furnizarii produselor din comanda?</li>
					<li>Cum apreciati calitatea produselor primite?</li>
					<li>Cat de usoara a fost comunicarea cu Partenerul Marketplace si CEL.ro?</li>
					<li>Cum calificati atitudinea generala a Partenerului Marketplace?</li>
				</ol>
				<p class="point">Coloanele numerotate cu <strong>N.*</strong> reprezinta notele primite la intrebarea respectiva</p>
			</div>
		</el-card>
		<div class="separator"></div>
		<Box :type="'warning'" :active="true" style="margin-top:0">
			<div slot="box-body">
				<p>Media evaluarilor este folosita ca indicator de performanta in calcularea sanatatii contului.</p>
				O medie scazuta poate contribui la <strong>inchiderea temporara sau permanenta a contului!</strong>
			</div>
		</Box>
		<div class="separator"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>Evaluari clienti</span>
			</div>
			<div class="body">
				<el-alert :type="scoreType" show-icon v-if="average > 0" :closable="false">
					<div slot="title">
						Media evaluarilor: <strong>{{average}}</strong>
					</div>
				</el-alert>
				<div class="mini-separator"></div>
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
				 :page-size="limit" @current-change="changePage">
				</el-pagination>
				<div class="mini-separator"></div>
				<el-table :data="feedback" style="width: 100%" :row-class-name="checkRowSeen">
					<el-table-column prop="survey_id" label="ID Chestionar" width="250px">
					</el-table-column>
					<el-table-column prop="orders_id" label="Comanda" width="100px">
						<template slot-scope="scope">
							<router-link :to="{path: '/vanzari/comanda/' + scope.row.orders_id}">{{scope.row.orders_id}}</router-link>
						</template>
					</el-table-column>
					<el-table-column prop="date_insert" label="Data" width="150px">
					</el-table-column>
					<el-table-column prop="response_1" label="N.1" width="60px">
						<template slot-scope="scope">
							<span :class="getMarkColor(scope.row.response_1)">{{scope.row.response_1}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="response_2" label="N.2" width="60px">
						<template slot-scope="scope">
							<span :class="getMarkColor(scope.row.response_2)">{{scope.row.response_2}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="response_3" label="N.3" width="60px">
						<template slot-scope="scope">
							<span :class="getMarkColor(scope.row.response_3)">{{scope.row.response_3}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="response_4" label="N.4" width="60px">
						<template slot-scope="scope">
							<span :class="getMarkColor(scope.row.response_4)">{{scope.row.response_4}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="response_5" label="N.5" width="60px">
						<template slot-scope="scope">
							<span :class="getMarkColor(scope.row.response_5)">{{scope.row.response_5}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="score" label="Media" idth="100px">
						<template slot-scope="scope">
							<span class="score" :class="getMarkColor(scope.row.score)">{{scope.row.score}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="extra_details" label="Comentarii">
					</el-table-column>
				</el-table>
				<div class="mini-separator"></div>
				<el-pagination class="center" layout="prev, pager, next, total" :current-page="currentPage" :total="count"
				 :page-size="limit" @current-change="changePage">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	const DDCard = () => import('@/components/DDCard');
	const Box = () => import('vue-info-box-element');

	import {
		getClientFeedbacks
	} from '@/api/comunicare';

	export default {
		components: {
			'dd-card': DDCard,
			Box
		},
		props: ['filterQuery'],
		data() {
			return {
				isCollapsed: true,
				filters: {
					from: {
						type: 'select',
						value: ''
					},
					to: {
						type: 'select',
						value: ''
					},
					comments: {
						type: 'select',
						value: ''
					},
					start: {
						type: 'page',
						value: 0
					},
					evaltype: {
						type: 'select',
						value: ''
					}
				},
				feedback: [],
				limit: 50,
				currentPage: 1,
				count: 0,
				average: 0
			};
		},
		methods: {
			applyFilters() {
				this.filters.start.value = 0;
				if (typeof this.updateQuery === 'function') this.updateQuery();
				this.getFeedbacks();
			},
			async getFeedbacks() {
				const params = {
					start: this.filters.start.value
				};
				if (this.filters.comments.value !== '') {
					params.comments = this.filters.comments.value;
				}
				if (this.filters.from.value !== '' && this.filters.from.value != null) {
					params.from = this.filters.from.value;
				}
				if (this.filters.to.value !== '' && this.filters.to.value != null) {
					params.to = this.filters.to.value;
				}
				if (this.filters.evaltype.value !== '' && this.filters.evaltype.value != null) {
					params.evaltype = this.filters.evaltype.value;
				}
				try {
					const result = await getClientFeedbacks(params);
					if (result.error == null || result.error === 1) throw new Error('Eroare la preluarea feedbackului');
					this.feedback = result.message.data;
					this.count = result.message.count;
					this.average = result.message.average;
				} catch (err) {
					return false;
				}
			},
			getMarkColor(mark) {
				return mark < 2 ? 'bad' : mark >= 2 && mark < 4 ? 'warn' : 'good';
			},
			changePage(page) {
				this.filters.start.value = page * this.limit - this.limit;
				this.currentPage = page;
				this.getFeedbacks();
			},
			checkRowSeen(data) {
				return data.row.seen === 0 ? 'unseen' : '';
			}
		},
		computed: {
			scoreType() {
				return this.average < 2 ? 'error' : this.average >= 2 && this.average < 4 ? 'warning' : 'success';
			},
		},
		mounted() {
			this.getFeedbacks();
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss">
	@import './src/styles/modules/comunicare.scss';

	.warn {
		color: orange;
	}

	.good {
		color: green;
	}

	.bad {
		color: red;
	}

	.score {
		font-weight: bold;
	}

	.point {
		font-size: 0.9em;
		color: #606266;
	}

	.questions {
		line-height: 1.5;
	}

	.unseen {
		background-color: #fffaf7 !important;
	}
</style>